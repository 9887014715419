<script setup lang="ts">
import { useDateFormatter } from '~/composables/dateformatter'

const props = withDefaults(defineProps<{
	isoDateTime: string,
	locale?: string,
	color?: 'mono' | 'white' | 'black',
}>(), {
	locale: 'en-GB',
	color: 'mono',
})

const dateFormatter = useDateFormatter()

const date = computed(() => {
	if (!props.isoDateTime) return ''
	return new Date(props.isoDateTime.replace('+0000', 'Z'))
})
const locales = computed(() => {
	return [props.locale, 'en-GB']
})
const formattedDateTime = computed(() => {
	if (!props.isoDateTime) return ''
	return new Intl.DateTimeFormat(locales.value, { ...dateFormatter.dayDateFormat, ...dateFormatter.timeDateFormat }).format(date.value)
})
const relativeDateTime = computed(() => {
	if (!props.isoDateTime) return ''
	if (!Intl.RelativeTimeFormat) return formattedDateTime.value
	const rtf = new Intl.RelativeTimeFormat(locales.value, { numeric: 'auto' })
	const msDiff = date.value - new Date()
	const absMsDiff = Math.abs(msDiff)
	const msPerSecond = 1000
	const msPerMinute = msPerSecond * 60
	const msPerHour = msPerMinute * 60
	const msPerDay = msPerHour * 24
	const msPerMonth = msPerDay * 30
	const msPerYear = msPerDay * 365
	if (absMsDiff < msPerMinute) {
		return rtf.format(Math.round(msDiff / msPerSecond), 'second')
	} else if (absMsDiff < msPerHour) {
		return rtf.format(Math.round(msDiff / msPerMinute), 'minute')
	} else if (absMsDiff < msPerDay) {
		return rtf.format(Math.round(msDiff / msPerHour), 'hour')
	} else if (absMsDiff < msPerMonth) {
		return rtf.format(Math.round(msDiff / msPerDay), 'day')
	} else if (absMsDiff < msPerYear) {
		return rtf.format(Math.round(msDiff / msPerMonth), 'month')
	} else {
		return rtf.format(Math.round(msDiff / msPerYear), 'year')
	}
})
const label = computed(() => {
	if (!props.isoDateTime) return ''
	return relativeDateTime.value
})
</script>

<template>
	<div
		class="flex items-center"
		:title="formattedDateTime"
	>
		<p
			:class="[{
				'text-mono-500': color === 'mono',
				'text-white': color === 'white',
				'text-mono-900': color === 'black',
			}, 'text-caption']"
		>
			{{ label }}
		</p>
	</div>
</template>
